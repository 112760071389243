.section8 {
  height: 400px;
  width: 100%;
  padding: 0;
  position: relative;
}

p {
  margin: 0px 10px;
}

.section8-container {
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
}


.section8-text {
  color: #f68f78;
  font-size: 32.74px;
  width: max-content;

  text-align: center;
  z-index: 10;
}


@media (max-width: 800px) {
  .section8-container {
    margin-top: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  
}




@media (max-width: 425px) {
  .section8 {
    height: 500px;
    width: 100%;
    padding: 0;
    position: relative;
  }
  .section8-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10vw;
    margin-right: 10vw;
    margin: 10vw 5vw;
  }
}