.section {
  width: 100%;
  padding: 0;
  position: relative;
  height: 794px;
}

p {
  padding: 0;
  margin: 0;
}

.section-container {
  width: 100%;
  height: max-content;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.section-text {
  font-size: 23.64px;
  color: #71192e;
  z-index: 1;
  text-align: center;
}

.section-overlay-container {
  width: 100%;
  z-index: 20;
}

.section-container2 {
  width: 100vw;
  position: relative;
}


.section1-text-container1 {
  position: absolute;
  top: 25%;
  width: 50%;

  padding-top: 50px;
}

.section1-text-container2 {
  position: absolute;
  top: 35%;

  padding-top: 20px;
}

.section1-title-text-overlay {
  position: absolute;
  right: 10%;
  bottom: 25%;

  height: 200px;
  width: auto;
  z-index: 200;
}

.section1-title-shape {
  z-index: 0;
  width: 280px;
  transform: scaleX(-1);
}

.section1-image {
  max-width: 100%;
  height: 80%;
  width: 80%;
  position: absolute;
  top: 60%;
  bottom: 0%;
  left: 50%;
  right: 0%;
  transform: translate(-50%, 0%);
  z-index: 30;
  border-radius: 10px;
}

.section1-flex-text-container {
  position: absolute;
  right: 5%;
  bottom: 25%;
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "flex-end";
}

.section1-flex-text-container-en {
  width: 10px;
  position: absolute;
  left: 5%;
  bottom: 20%;
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "flex-end";
}

.section-text-image-container {
  width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section1-shape {
  position: absolute;
  right: 3%;
  bottom: 10%;
  width: 20vw;
  height: auto;
  transform: scaleX(-1);
}

.section1-shape-en {
  position: absolute;
  left: 3%;
  bottom: 10%;
  width: 20vw;
  height: auto;
}


.section-text-overlayed {
  font-size: 31.64px;
  color: #71192e;
  z-index: 1;
  text-align: center;
  width: 15vw;
  position: absolute;
  top: 60%;
  right: 5%;
}

.section1-text {
  font-size: 31.64px;
  color: #71192e;
  z-index: 1;
  text-align: right;
}

.section1-text-en {
  font-size: 31.64px;
  color: #71192e;
  z-index: 1;
  text-align: left;
}


.main-background-image {
  background-image: url(../../assets/images/MainImage.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 3000px) {
  .section1-text-en {
    font-size: 44.64px;
    color: #71192e;
    z-index: 1;
    text-align: left;
  }

  .section-text {
    font-size: 36.64px;
    color: #71192e;
    z-index: 1;
    text-align: center;
  }
  
  .section1-text {
    font-size: 41.64px;
    color: #71192e;
    z-index: 1;
    text-align: right;
  }

}

@media (min-width: 2000px) {
  .section1-shape {
    position: absolute;
    right: 3%;
    bottom: 10%;
    width: 15vw;
    height: auto;
    transform: scaleX(-1);
  }
  .section1-text {
    font-size: 31.64px;
    color: #71192e;
    z-index: 1;
    text-align: right;
  }
  .section1-flex-text-container-en {
    width: 10px;
    position: absolute;
    left: 5%;
    bottom: 12%;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-end";
  }

  .section1-shape-en {
    position: absolute;
    left: 3%;
    bottom: 5%;
    width: 15vw;
    height: auto;
  }
}

@media (max-width: 1500px) {
  .section1-text-container1 {
    position: absolute;
    top: 20%;
    width: 50%;
  }

  .section1-text-en {
    font-size: 24.64px;
    color: #71192e;
    z-index: 1;
    text-align: left;
  }

  .section-text {
    font-size: 21.64px;
    color: #71192e;
    z-index: 1;
    text-align: center;
  }
  
  .section {
    width: 100%;
    padding: 0;
    position: relative;
    height: 594px;
  }

  .section1-image {
    max-width: 100%;

    width: 80vw;
    position: absolute;
    top: 60%;
    bottom: 0%;
    left: 50%;
    right: 0%;
    transform: translate(-50%, 0%);
    z-index: 10;
    border-radius: 10px;
  }
}

@media (max-width: 1200px) {
  .section1-text-container1 {
    position: absolute;
    top: 20%;
    width: 50%;
  }
  .section-text {
    font-size: 19.64px;
    color: #71192e;
    z-index: 1;
    text-align: center;
  }
  
  .section {
    width: 100%;
    padding: 0;
    position: relative;
    height: 594px;
  }

  .section1-flex-text-container {
    position: absolute;
    right: 5%;
    bottom: 25%;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-end";
  }

  .section1-flex-text-container-en {
    width: 10px;
    position: absolute;
    left: 5%;
    bottom: 20%;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-end";
  }

  .section1-shape {
    position: absolute;
    right: 3%;
    bottom: 10%;
    width: 250px;
    height: auto;
    transform: scaleX(-1);
  }

  .section1-shape-en {
    position: absolute;
    left: 3%;
    bottom: 10%;
    width: 25vw;
    height: auto;
  }

  .section1-text {
    font-size: 26.64px;
    color: #71192e;
    z-index: 1;
    text-align: right;
  }

  .section1-text-en {
    font-size: 24.64px;
    color: #71192e;
    z-index: 1;
    text-align: left;
  }

  .section1-image {
    max-width: 100%;

    width: 90vw;
    position: absolute;
    top: 60%;
    bottom: 0%;
    left: 50%;
    right: 0%;
    transform: translate(-50%, 0%);
    z-index: 10;
    border-radius: 10px;
  }
}

@media (max-width: 810px) {
  .section1-text-container1 {
    position: absolute;
    top: 20%;
    width: 100%;
    margin-bottom: 20px;
  }

  .section1-flex-text-container-en {
    width: 10px;
    position: absolute;
    left: 5%;
    bottom: 12%;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-end";
  }

  .section1-shape-en {
    position: absolute;
    left: 3%;
    bottom: 10%;
    width: 30vw;
    height: auto;
  }

  .main-background-image {
    background-size: cover; /* Use cover to make the image cover the entire container */
    background-position: center; /* Center the image horizontally and vertically */
  }
}

@media (max-width: 650px) {
  .section {
    width: 100%;
    padding: 0;
    position: relative;
    height: 794px;
  }

  .section1-text-container1 {
    position: absolute;
    top: 20%;
    width: 100%;
    margin-bottom: 20px;
  }

  .section1-flex-text-container {
    position: absolute;
    right: 5%;
    bottom: 25%;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-end";
  }

  .section1-flex-text-container-en {
    width: 10px;
    position: absolute;
    left: 5%;
    bottom: 12%;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-end";
  }

  .section1-shape-en {
    position: absolute;
    left: 3%;
    bottom: 5%;
    width: 45vw;
    height: auto;
  }
}

@media (max-width: 475px) {

  .section1-text-container1 {
    position: absolute;
    top: 25%;
    width: 80%;
  }
  
  .section1-flex-text-container-en {
    width: 10px;
    position: absolute;
    left: 5%;
    bottom: 12%;
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "flex-end";
  }

  .section1-shape-en {
    position: absolute;
    left: 3%;
    bottom: 5%;
    width: 50vw;
    height: auto;
  }
}


@media (max-width: 410px) {
  .section1-text-en {
    font-size: 19.64px;
    color: #71192e;
    z-index: 1;
    text-align: left;
  }
  .section1-text-container1 {
    position: absolute;
    top: 25%;
    width: 100%;
  }
  .section1-shape-en {
    position: absolute;
    left: 3%;
    bottom: 5%;
    width: 60vw;
    height: auto;
  }
}

