.navbar {
  background-color: #fff;

  padding: 1rem 2rem;
  text-align: center;
  font-size: 17.37px;
  margin-top: 0px;
}

.navbar-rectangle {
  width: 100vw;
  height: 50px;
  z-index: 10;
  background-color: #f68f78;
}

.fixed-navbar {
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-container {
  width: 100vw;
  height: 100%;
  margin: 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 0px;
  align-items: center;
  padding: 10px 20vw;
}

.nav-menu {
  list-style-type: none;
  display: flex;
  gap: 4rem;
  margin: 0px 0px;
}

.navbar-links-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.nav-link {
  text-decoration: none;
  color: #71192e;
  white-space: nowrap;
}

.nav-link:hover {
  text-decoration: underline;
}

.logo {
}

.website-logo {
  width: 147.75px;
  height: 46.57px;
}

.language-switcher {
  background: none;
  border: 0.89px solid #71192e;
  padding: 10px;
  cursor: pointer;
  color: #71192e;
  font-size: 18px;
}

.language-dropdown {
  position: relative;
  width: max-content;
}

.nav-item {
  font-size: 16px;
  flex-shrink: 1;
}

.languages-list {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 5px;
  z-index: 1000;
  width: max-content;
}

.languages-list span {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  color: #71192e;
}

.languages-list span:hover {
  background-color: #71192e;
  color: #fff;
}

.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #71192e;
}

@media (min-width: 3000px) {
  .website-logo {
    width: 207.75px;
    height: auto;
  }
  .navbar {
    background-color: #fff;
    height: max-content;
    padding: 0rem 0rem;
    text-align: center;
    font-size: 32.37px;
    margin-top: 0px;
  }
}

@media (max-width: 1000px) {
  .navbar-container {
    width: 100vw;
    height: 100%;
    margin: 0px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    column-gap: 0px;
    align-items: center;
    padding: 10px 2vw;
  }

  .website-logo {
    width: 120.75px;
    height: auto;
  }
}

@media (max-width: 650px) {
  .logo {
    padding-top: 10px;
  }

  .navbar-container {
    width: 100%;
    height: max-content;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    row-gap: 30px;
    column-gap: 0px;
    align-items: center;
    padding-top: 15px;
  }
  .navbar {
    background-color: #fff;
    height: max-content;
    padding: 0rem 0rem;
    text-align: center;
    font-size: 20.37px;
    margin-top: 0px;
  }

  .nav-menu {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    column-gap: 30px;
    row-gap: 0px;
    padding: 5px;
    margin: 0px 0px;
    flex-shrink: 1;
  }

  .navbar-rectangle {
    width: 100vw;
    height: 10px;
    z-index: 10;
    background-color: #f68f78;
  }

  .language-dropdown {
    position: relative;
    word-break: keep-all;
  }

  .language-switcher {
    background: none;
    border: 0.89px solid #71192e;
    padding: 5px;
    cursor: pointer;
    color: #71192e;
  }

  .nav-item {
    width: max-content;
    height: auto;
    word-break: keep-all;
    font-size: 17px;
  }
}

@media (max-width: 475px) {
  .logo {
    padding-top: 10px;
  }

  .language-dropdown {
    position: absolute;
    word-break: keep-all;
  }

  .navbar-container {
    width: 100%;
    height: max-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;
    column-gap: 0px;
    align-items: center;
    padding-top: 15px;
  }
  .navbar {
    background-color: #fff;
    height: max-content;
    padding: 0rem 0rem;
    text-align: center;
    font-size: 20.37px;
    margin-top: 0px;
  }

  .nav-menu {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    column-gap: 15px;
    row-gap: 0px;
    padding: 5px;
    margin-bottom: 0px;
    font-size: 18px;
  }

  .navbar-rectangle {
    width: 100vw;
    height: 10px;
    z-index: 10;
    background-color: #f68f78;
  }

  .language-dropdown {
    position: relative;
    word-break: keep-all;
  }

  .language-switcher {
    background: none;
    border: 0.89px solid #71192e;
    padding: 5px;
    cursor: pointer;
    color: #71192e;
  }

  .nav-item {
    width: max-content;
    height: auto;
    word-break: keep-all;
  }

  .language-switcher {
    background: none;
    border: 0.89px solid #71192e;
    padding: 3px;
    cursor: pointer;
    color: #71192e;
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .nav-menu {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    column-gap: 7px;
    row-gap: 0px;
    padding: 5px;
    margin-bottom: 0px;
    font-size: 18px;
  }

  .navbar {
    background-color: #fff;
    height: max-content;
    padding: 0rem 0rem;
    text-align: center;
    margin-top: 0px;
  }

  .nav-link {
    text-decoration: none;
    color: #71192e;
    white-space: nowrap;
    font-size: 16px;
  }

  .language-switcher {
    background: none;
    border: 0.89px solid #71192e;
    padding: 3px;
    cursor: pointer;
    color: #71192e;
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .nav-menu {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    column-gap: 7px;
    row-gap: 0px;
    padding: 5px;
    margin-bottom: 0px;
    font-size: 18px;
  }

  .navbar {
    background-color: #fff;
    height: max-content;
    padding: 0rem 0rem;
    text-align: center;
    margin-top: 0px;
  }

  .nav-link {
    text-decoration: none;
    color: #71192e;
    white-space: nowrap;
    font-size: 14px;
  }

  .language-switcher {
    background: none;
    border: 0.89px solid #71192e;
    padding: 3px;
    cursor: pointer;
    color: #71192e;
    font-size: 12px;
  }
}
