.section7 {
  height: max-content;
  width: 100vw;
  position: relative;
  padding-bottom: 0px;
  overflow-y: hidden;
}

.section7-rectangle {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 50px;
  z-index: 10;
  background-color: #283755;
}


.section7-container {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  justify-content: space-evenly;
  align-items: flex-start;
  width: auto;
  height: auto;
  padding-top: 30px;

}



.section7-image {
  width: 100%;
  height: auto;  

}

.section7-image-container {
  width: 300px;
  margin: 60px 0px;
  position: absolute;
  top: 5%;
  left: 2%;
}

@media (min-width: 3000px) {
  .section7-image-container {
    width: 500px;
    margin: 60px 0px;
  }
}

@media (max-width: 1440px) {
  .section7-image-container {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .section7-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
  }
  .section7-image-container {
    width: 30%;
    margin-top: 50px;
    margin-left: 50px;
  }

  .section7-image {
    width: 100%;
    height: auto;  
  
  }
  
  .section7-image-container {
    width: 150px;
    margin: 60px 0px;
    position: absolute;
    top: 1%;
    left: 2%;
  }
  
}

@media (max-width: 475px) {
  .section7-image-container {
    width: 0vw;
    height: auto;
    margin: auto;
    position: relative;
  }

  
  
}