.section4 {
  height: max-content;
  width: 100vw;
  position: relative;
  padding: 200px 0px; /*change the Y value for responsive behavior */
}

.section4-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: max-content;

  margin-top: 50px;
}

.section4-centered-container {
  margin: auto auto;
  width: 50%;
  height: 100%;
}

.section4-text {
  color: #711930;
  font-size: 24.74px;
  width: 100%;
  text-align: center;
  margin: auto auto;
}

.section4-image {
  max-width: max-content;
  height: 50%;
  position: absolute;
  left: 8%;
  top: 3%;
}

.section4-image2 {
  max-width: max-content;
  height: 50%;
  position: absolute;
  right: 5%;
  bottom: -10%;
  z-index: 10;
}

@media (min-width: 3000px) {
  .section4-centered-container {
    margin: auto auto;
    width: 30%;
    height: 100%;
  }
  
.section4-text {
  color: #711930;
  font-size: 36.74px;
  width: 100%;
  text-align: center;
  margin: auto auto;
}

.section4-image {
  max-width: max-content;
  height: 80%;
  position: absolute;
  left: 15%;
  top: 3%;
}

.section4-image2 {
  max-width: max-content;
  height: 100%;
  position: absolute;
  right: 12%;
  bottom: -10%;
  z-index: 10;
}
}


@media (max-width: 1010px) {
  .section4-image {
    max-width: max-content;
    height: 45%;
    position: absolute;
    left: 4%;
    top: 2%;
  }

  .section4-image2 {
    max-width: max-content;
    height: 50%;
    position: absolute;
    right: 2%;
    bottom: -10%;
    z-index: 10;
  }

  .section4-centered-container {
    margin: auto auto;
    width: 50%;
    height: 100%;
  }

  .section4-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: max-content;
    margin-top: 90px;
  }
}

@media (max-width: 740px) {
  .section4-image {
    max-width: max-content;
    height: 40%;
    position: absolute;
    left: 2%;
    top: 2%;
  }

  .section4-image2 {
    max-width: max-content;
    height: 40%;
    position: absolute;
    right: 2%;
    bottom: -12%;
    z-index: 10;
  }

  .section4-centered-container {
    margin: auto auto;
    width: 80%;
    height: 100%;
  }

  .section4-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: max-content;
    margin-top: 100px;
  }
}

@media (max-width: 425px) {
  .section4-image {
    max-width: max-content;
    height: 40%;
    position: absolute;
    left: 2%;
    top: 2%;
  }

  .section4-image2 {
    max-width: max-content;
    height: 40%;
    position: absolute;
    right: 2%;
    bottom: -12%;
    z-index: 10;
  }

  .section4-centered-container {
    margin: auto auto;
    width: 90%;
    height: 100%;
  }

  .section4-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: max-content;
    margin-top: 100px;
  }
}
