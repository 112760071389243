.section-3 {
  padding-top: 300px;
  width: 100%;
  height: 600px;
  position: relative;

  margin: auto auto;
}

.section3-background {
  
}

.section3-map {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0%;
  bottom: 0%;

  transform: translate(-50%, -50%);

  width: max-content;
  height: 100%;
  mix-blend-mode: multiply;
}

.section3-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;

  margin: auto auto;
  width: 50%;
}

.section3-text {
  font-size: 27.64px;
  color: #fff;
  z-index: 20;
  text-align: center;
  position: relative;
  top: 17%;
  width: 100%;
}



@media (min-width: 3000px) {
  .section3-text {
    font-size: 36.64px;
    color: #fff;
    z-index: 20;
    text-align: center;
    position: relative;
    top: 17%;
    width: 100%;
  }
}

@media (max-width: 1240px) {
  .section-3 {
    padding-top: 200px;
    width: 100%;
    height: 600px;
    position: relative;
  
    margin: auto auto;
  }
  
}

@media (max-width: 540px) {
  .section3-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
  
    margin: auto auto;
    width: 70%;
  }
  
}

