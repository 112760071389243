.App {
  text-align: center;
  height: max-content;

  overflow-x: hidden;
  overflow-y: hidden;

  width: 100%;
  background-color: #F68F78;

  font-family: 'Inter';
  font-weight: 400;
  user-select: none;
  scroll-behavior: smooth;
  
}

@font-face {
  font-family: 'Cadiz';
  src: url(./assets/fonts/Cadiz-Book.otf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Thameen';
  src: url('./assets/fonts/GE_Thameen_Book.otf') format('opentype');
}

.slide-in-bottom {
  transform: translateY(100%);
  opacity: 0;
  transition: all 1s;
}

.slide-in-bottom.show {
  transform: translateY(0);
  opacity: 1;
}

.slide-in-top {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 1s;
}

.slide-in-top.show {
  transform: translateY(0);
  opacity: 1;
}



.scale-up {
  transform: scale(0.5);
  opacity: 0;
  transition: all 1s;
}

.scale-up.show {
  transform: scale(1);
  opacity: 1;
}
