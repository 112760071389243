.section5 {
  height: max-content;
  width: 100vw;
  position: relative;
  padding-top: 180px;
  padding-bottom: 50px;
}

.section5-container {
  width: 100vw;
  height: 100%;
  margin: auto auto;
}

.section5-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: max-content;
  row-gap: 50px;
  margin-top: 50px;
}

.section5-centered-container {
  margin: auto auto;
  width: 50%;
  height: 100%;
}

.section5-text-align-left {
  align-self: flex-end;
}

.section5-text {
  color: #283755;
  font-size: 24.74px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.section5-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px;
  width: 100%;
  height: 100%;
}

.section5-vertical-line {
  height: 100px;
  border-left: #fff;
  border: 1px solid #283755;
  margin: 0 auto;
}

.checkmark-container {
  display: inline-block;
  position: relative;
  width: 30px; /* Adjust size as needed */
  height: 15px; /* Adjust size as needed */
}

.checkmark {
  width: 100%;
  height: 100%;
  transform: rotate(-45deg); /* Rotates the checkmark shape */
  border: 2px solid #283755; /* Checkmark color */
  border-top: none;
  border-right: none;
  position: absolute;
  top: 20%;
  right: 15%;
}

.section5-gallery-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 50px auto;

  column-gap: 15px;
  row-gap: 15px;
}

.section5-image {
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  height: auto;
  transition: transform 0.7s ease; /* Add a transition for smooth animation */
  cursor: pointer;
}

.section5-image-frame {
  border-radius: 10px;
  width: 20%; /* Adjust this width to control spacing between images */
  margin: 0;
  padding: 0;
  overflow: hidden; /* Ensure the image stays within the frame */
  position: relative;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);

}

.section5-image-caption {
  position: absolute;
  z-index: 20;
  width: 70%;
  background-color: #fff;
  padding: 10px 0px;
  margin: auto auto;

  bottom: 5%;
  left: 50%;
  transform: translate(-50%);

  border: 1px solid #fff;
  border-radius: 5px;
}

.section5-image-frame:hover .section5-image {
  transform: scale(1.1); /* Apply zoom-in effect on hover */
}

@media (min-width: 3000px) {
  .section5-text {
    color: #283755;
    font-size: 36.74px;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .section5-image-caption {
    position: absolute;
    z-index: 20;
    width: 70%;
    background-color: #fff;
    padding: 10px 0px;
    margin: auto auto;
  
    bottom: 5%;
    left: 50%;
    transform: translate(-50%);
  
    border: 1px solid #fff;
    border-radius: 5px;

    font-size: 26px;
  }
  
  
}

@media (max-width: 1000px) {
  .section5-image-frame {
    border-radius: 10px;
    width: 50%; /* Adjust this width to control spacing between images */
    margin: 0;
    padding: 0;
    overflow: hidden; /* Ensure the image stays within the frame */
    position: relative;
  }

  .section5-gallery-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin: 50px auto;

    column-gap: 15px;
    row-gap: 15px;
  }

  .section5-centered-container {
    margin: auto auto;
    width: 70%;
    height: 100%;
  }
  
}

@media (max-width: 650px) {
  .section5-image-frame {
    border-radius: 10px;
    width: 50%; /* Adjust this width to control spacing between images */
    margin: 0;
    padding: 0;
    overflow: hidden; /* Ensure the image stays within the frame */
    position: relative;
  }

  .section5-gallery-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin: 50px auto;
    
    column-gap: 15px;
    row-gap: 25px;
  }

  .section5-centered-container {
    margin: auto auto;
    width: 80%;
    height: 100%;
  }
}
