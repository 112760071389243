.section6 {
  height: max-content;
  width: 100%;
  padding: 100px;
  position: relative;
  margin-bottom: 0px;
}

p {
  margin: 0px 10px;
}

.section6-container {
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section6-title-text {
  color: #711930;
  font-size: 28px;
  width: 45%;
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
}

.section6-text {
  color: #711930;
  font-size: 31.74px;
  width: max-content;

  text-align: center;
}

.section6-text2 {
  color: #711930;
  font-size: 23px;
  width: max-content;
  text-align: center;
  margin-bottom: 20px;
}

.section6-text-border {
  border: #f79c88 solid 2px;
  padding: 0px 10px;
  border-radius: 50px;
  margin-bottom: 70px;
}

.section6-image-gallery-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  column-gap: 20px;
}

.section6-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.section6-image {
  margin-top: 0px;
  z-index: 5;
  width: 368px;
  height: 277px;
}

.section6-download-here-text {
  color: #fff;
  font-size: 22.44px;
  text-align: center;
  user-select: none;
}

.section6-download-button {
  background-color: #4b6ccc;
  width: 100%;
  height: auto;
  border-radius: 4px;
  padding: 20px 7px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.section6-download-button:active {
  transform: scale(0.95);
  background-color: #e5d5cf;
}

@media (min-width: 3000px) {
  .section6-download-button {
    background-color: #4b6ccc;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 20px 7px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .section6-download-here-text {
    color: #fff;
    font-size: 30.44px;
    text-align: center;
    user-select: none;
  }

  .section6-image {
    margin-top: 0px;
    z-index: 5;
    width: 558px;
    height: auto;
  }

  .section6-text {
    color: #711930;
    font-size: 31.74px;
    width: max-content;
  
    text-align: center;
  }

  .section6-title-text {
    color: #711930;
    font-size: 32px;
    width: 45%;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
  }
  
  
  .section6-text2 {
    color: #711930;
    font-size: 29px;
    width: max-content;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 1440px) {
  .section6 {
    height: 1300px;
    width: 100%;
    padding: 20px;
    position: relative;
    margin-bottom: 0px;
  }

  .section6-container {
    margin-top: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section6-title-text {
    color: #711930;
    font-size: 19px;
    width: 75%;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .section6-text {
    color: #711930;
    font-size: 24.74px;
    width: max-content;

    text-align: center;
  }

  .section6-text2 {
    color: #711930;
    font-size: 19px;
    width: max-content;
    text-align: center;
    margin-bottom: 20px;
  }

  .section6-image-gallery-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
  }

  .section6-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .section6-image {
    margin-top: 0px;
    z-index: 5;
    width: 22vw;
    height: auto;
  }

  .section6-download-button {
    background-color: #4b6ccc;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 20px 7px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

@media (max-width: 768px) {
  .section6 {
    height: max-content;
    width: 100%;
    padding: 20px;
    position: relative;
    margin-bottom: 0px;
  }

  .section6-image-gallery-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
  }

  .section6-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .section6-image {
    margin-top: 0px;
    z-index: 5;
    width: 40vw;
    height: auto;
  }

  .section6-download-button {
    background-color: #4b6ccc;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 20px 7px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}



@media (max-width: 425px) {
  .section6 {
    height: 1750px;
    width: 100%;
    padding: 20px;
    position: relative;
    margin-bottom: 0px;
  }

  .section6-title-text {
    color: #711930;
    font-size: 19px;
    width: 100%;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .section6-text {
    color: #711930;
    font-size: 20.74px;
    width: 95vw;

    text-align: center;
  }

  .section6-text2 {
    color: #711930;
    font-size: 19px;
    width: max-content;
    text-align: center;
    margin-bottom: 20px;
  }

  .section6-image-gallery-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    column-gap: 20px;
  }

  .section6-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .section6-image {
    margin-top: 0px;
    z-index: 5;
    width: 70vw;
    height: auto;
  }

  .section6-download-button {
    background-color: #4b6ccc;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 20px 7px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}


@media (max-width: 375px) {
  .section6 {
    height: 1650px;
    width: 100%;
    padding: 20px;
    position: relative;
    margin-bottom: 0px;
  }

  .section6-title-text {
    color: #711930;
    font-size: 18px;
    width: 100%;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .section6-text {
    color: #711930;
    font-size: 17.74px;
    width: 95vw;

    text-align: center;
  }

  .section6-text2 {
    color: #711930;
    font-size: 19px;
    width: max-content;
    text-align: center;
    margin-bottom: 20px;
  }

  .section6-image-gallery-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    column-gap: 20px;
  }

  .section6-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .section6-image {
    margin-top: 0px;
    z-index: 5;
    width: 70vw;
    height: auto;
  }

  .section6-download-button {
    background-color: #4b6ccc;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 20px 7px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}


@media (max-width: 320px) {
  .section6 {
    height: 1500px;
    width: 100%;
    padding: 20px;
    position: relative;
    margin-bottom: 0px;
  }

  .section6-title-text {
    color: #711930;
    font-size: 17px;
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .section6-text {
    color: #711930;
    font-size: 15.74px;
    width: 99vw;
    text-align: center;
    padding: 0;
  }

  .section6-text2 {
    color: #711930;
    font-size: 19px;
    width: max-content;
    text-align: center;
    margin-bottom: 20px;
  }

  .section6-image-gallery-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    column-gap: 20px;
  }

  .section6-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .section6-image {
    margin-top: 0px;
    z-index: 5;
    width: 70vw;
    height: auto;
  }

  .section6-download-button {
    background-color: #4b6ccc;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 20px 7px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}