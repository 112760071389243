.footer {
  width: 100%;
  height: 350px;
  margin: auto auto;
  border-radius: 10px;
}

p {
  padding: 0;
  margin: 0;
}

.footer-text {
  color: #f68f78;
  font-size: 18px;
  width: 590px;

  text-align: left;
  z-index: 10;
}

.footer-text2 {
  color: #f68f78;
  font-size: 18px;
  width: 590px;

  margin-top: 30px;
  text-align: left;
  z-index: 10;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;

  width: 100%;
  height: 400px;
}

.footer-container-left {
  position: absolute;
  left: 15%;
  bottom: 30%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px;
}

.footer-container-right {
  position: absolute;
  right: 15%;
  bottom: 30%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  column-gap: 20px;

  width: 300px;
}

.footer-image {
  z-index: 5;
  width: 148px;
  height: auto;

  margin-bottom: 10px;
}

.footer-book-button {
  color: #71192e;
  background-color: #ffede5;
  padding: 10px 20px;
  border-radius: 3px;
  border: #ffede5 solid 1px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.footer-book-button:hover {
  background-color: #71192e;
  color: #ffede5;
}

.footer-book-button:active {
  transform: scale(0.95);
  background-color: #e5d5cf;
}

.arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 20px;
  border-left: 10px solid #71192e;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.arrow-right:hover {
  border-left: 10px solid #e5d5cf;
}


@media (min-width: 3000px) {
  .footer-text {
    color: #f68f78;
    font-size: 28px;
    width: 590px;
  
    text-align: left;
    z-index: 10;
  }
  
  .footer-text2 {
    color: #f68f78;
    font-size: 28px;
    width: 590px;
  
    margin-top: 30px;
    text-align: left;
    z-index: 10;
  }

  .footer-book-button {
    color: #71192e;
    background-color: #ffede5;
    padding: 10px 20px;
    border-radius: 3px;
    border: #ffede5 solid 1px;
    transition: all 0.3s ease;
    cursor: pointer;  
    width: max-content;

    font-size: 26px;
  }
}

@media (max-width: 790px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;

    width: 100%;
    height: 100%;
    position: relative;
  }

  .footer-text {
    color: #f68f78;
    font-size: 18px;
    width: 100%;

    text-align: left;
    z-index: 10;
  }

  .footer-container-left {
    position: absolute;
    left: 3%;
    bottom: 12%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
  }

  .footer-container-right {
    position: absolute;
    right: 10%;
    top: 10%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    column-gap: 0px;

    width: 100%;
    height: 100%;
  }

  .footer-book-button {
    color: #71192e;
    background-color: #ffede5;
    padding: 10px 20px;
    border-radius: 3px;
    transition: all 0.3s ease;
  }

  .footer-book-button:active {
    transform: scale(0.95);
    background-color: #e5d5cf;
  }
}

@media (max-width: 425px) {
  .footer {
    width: 100%;
    height: 450px;
    margin: auto auto;
    border-radius: 10px;
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;

    width: 100%;
    height: 100%;
    position: relative;
  }

  .footer-text2 {
    color: #f68f78;
    font-size: 18px;
    width: 100%;

    margin-top: 30px;
    text-align: left;
    z-index: 10;
  }

  .footer-text {
    color: #f68f78;
    font-size: 18px;
    width: 70vw;

    text-align: left;
    z-index: 10;
  }

  .footer-container-left {
    position: absolute;
    left: 7%;
    bottom: 12%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
  }

  .footer-container-right {
    position: absolute;
    right: 10%;
    top: 10%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    column-gap: 0px;

    width: max-content;
    height: max-content;
  }

  .footer-book-button {
    color: #71192e;
    background-color: #ffede5;
    padding: 10px 20px;
    border-radius: 3px;
    transition: all 0.3s ease;
  }

  .footer-book-button:active {
    transform: scale(0.95);
    background-color: #e5d5cf;
  }
}


@media (max-width: 320px) {
  .footer {
    width: 100%;
    height: 450px;
    margin: auto auto;
    border-radius: 10px;
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;

    width: 100%;
    height: 100%;
    position: relative;
  }

  .footer-text2 {
    color: #f68f78;
    font-size: 18px;
    width: 100%;

    margin-top: 30px;
    text-align: left;
    z-index: 10;
  }

  .footer-text {
    color: #f68f78;
    font-size: 18px;
    width: 70vw;

    text-align: left;
    z-index: 10;
  }

  .footer-container-left {
    position: absolute;
    left: 7%;
    bottom: 12%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
  }

  .footer-container-right {
    position: absolute;
    right: 10%;
    top: 5%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    column-gap: 10px;

    width: 100%;
    height: 100%;
  }

  .footer-book-button {
    color: #71192e;
    background-color: #ffede5;
    padding: 10px 20px;
    border-radius: 3px;
    transition: all 0.3s ease;
  }

  .footer-book-button:active {
    transform: scale(0.95);
    background-color: #e5d5cf;
  }
}
