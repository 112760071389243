.section-2 {
  padding-top: 400px;
  width: 80%;
  height: 1000px;
  position: relative;

  margin: auto auto;
}

.section2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;

  margin: auto auto;
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.show {
  opacity: 1;
}

.section2-text {
  font-size: 32.64px;
  color: #71192e;
  z-index: 20;
  text-align: center;
  position: relative;
  top: 17%;
  width: 100%;
}

.section2-image-gallery {
  z-index: 5;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  column-gap: 30px;
}

.gallery-image {
  width: 25vw;
  height: auto;
}

@media (min-width: 2700px) {
  .section2-image-gallery {
    z-index: 5;
  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    column-gap: 30px;
  }
  
  .gallery-image {
    width: 600px;
    height: auto;
  }
  .section2-text {
    font-size: 38.64px;
    color: #71192e;
    z-index: 20;
    text-align: center;
    position: relative;
    top: 17%;
    width: 100%;
  }
  
}

@media (max-width: 2000px) {
  .gallery-image {
    width: 30vw;
    height: auto;
  }
}

@media (max-width: 1700px) {
  .section-2 {
    padding-top: 400px;
    width: 80%;
    height: 800px;
    position: relative;

    margin: auto auto;
  }
}

@media (max-width: 1100px) {
  .gallery-image {
    width: 30vw;
    height: auto;
  }
}

@media (max-width: 768px) {
  .section-2 {
    padding-top: 400px;
    width: 80%;
    height: 1900px;
    position: relative;

    margin: auto auto;
  }

  .section2-image-gallery {
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    row-gap: 30px;
  }

  .gallery-image {
    width: 60vw;
    height: auto;
  }
}

@media (max-width: 600px) {
  .section-2 {
    padding-top: 400px;
    width: 80%;
    height: 1500px;
    position: relative;

    margin: auto auto;
  }

  .section2-image-gallery {
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    row-gap: 30px;
  }

  .gallery-image {
    width: 60vw;
    height: auto;
  }
}
@media (max-width: 425px) {
  .section-2 {
    padding-top: 400px;
    width: 80%;
    height: 1300px;
    position: relative;

    margin: auto auto;
  }

  .section2-image-gallery {
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    row-gap: 30px;
  }

  .gallery-image {
    width: 60vw;
    height: auto;
  }
}

@media (max-width: 320px) {
  .section-2 {
    padding-top: 400px;
    width: 80%;
    height: 1200px;
    position: relative;

    margin: auto auto;
  }

  .section2-image-gallery {
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    row-gap: 30px;
  }

  .gallery-image {
    width: 60vw;
    height: auto;
  }
}
